import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildConfigurationTableViewBaseUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { TableView, TableViews } from '../types';

export const loadTableViews = (
    actionName: string,
    processResultFunction: Function,
): Observable<{ result: TableView[]; actionName: string; processResultFunction: Function }> => {
    const url = buildConfigurationTableViewBaseUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<TableViews>) => {
            const { TableView } = response;
            const result = convertToCamel<TableView[]>(TableView);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
